export default theme => ({
  // eslint-disable-line
  para: {
    display: "block",
    position: "relative",
    fontSize: 16,
    lineHeight: 1.8,
    marginTop: 0,
    marginBottom: 32,
    fontFamily: theme.bodyFont,
    maxWidth: 1142,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0 12px",
    color: theme.palette.primary.navy,
    letterSpacing: "0.016em",
    "&.small": {
      fontSize: 14,
      lineHeight: 1.7,
      marginBottom: 12,
      letterSpacing: "0.016em",
    },
    "&.caption": {
      fontSize: 12,
      lineHeight: 1.33,
      marginBottom: 12,
      fontWeight: "bold",
      letterSpacing: "0.152em",
    },
    "&.lead": {
      fontSize: 22,
      lineHeight: 1.45,
      [theme.breakpoints.up("lg")]: {
        fontSize: 24,
        lineHeight: 1.33,
      },
    },
    "& a": {
      textDecoration: "underline !important",
      color: "#009f4d",
      fontWeight: "bold",
      "&:hover, &:focus": {
        color: "#B3D57C",
      },
    },
    // Alignment
    '&[data-align="left"]': { textAlign: "left" },
    '&[data-align="center"]': { textAlign: "center" },
    '&[data-align="right"]': { textAlign: "right" },
    // Background Colours
    '&:not([data-override-background="initial"])': { padding: 12 },
    // DropCap
    '&[data-drop-cap="true"]': {
      "&:first-letter": {
        float: "left",
        fontSize: "6rem",
        lineHeight: 0.65,
        margin: "0.1em 0.1em 0.2em 0",
      },
    },
  },
})
