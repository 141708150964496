import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Category from "../components/Products/Pages/CategoryPage"
import SubCategory from "../components/Products/Pages/SubCategoryPage"
import CookiesConsent from "../components/CookiesConsent"
import { isBrowser } from "../helpers"

class Page extends Component {
  constructor(props) {
    super(props)

    if (!isBrowser) return

    let script = document.querySelector(
      `script[src="https://api.feefo.com/api/javascript/zest"]`
    )

    if (script) {
      script.parentNode.removeChild(script)
    }

    script = document.createElement("script")
    script.src = "https://api.feefo.com/api/javascript/zest"
    script.async = true
    script.type = "text/javascript"
    document.body.appendChild(script)
  }
  render() {
    const isCategory = !this.props.data.wpProductCategory.wpParent
    const isSubcategory = !!this.props.data.wpProductCategory.wpParent

    const subcategories = this.props.data.wpProductCategory.wpChildren.nodes
    const allProducts = this.props.data.allWpProduct.nodes

    subcategories.map(subcategory => {
      const id = subcategory.id
      const products = allProducts.filter(product =>
        product.productCategories.nodes
          .map(category => category.id)
          .includes(id)
      )
      subcategory.products = products

      return subcategory
    })

    return (
      <Layout
        meta={{
          metaDescription: this.props.data.wpProductCategory.CollectionData
            .metaDescription
            ? this.props.data.wpProductCategory.CollectionData.metaDescription
            : this.props.data.wpProductCategory.description,
          metaTitle: this.props.data.wpProductCategory.CollectionData.metaTitle
            ? this.props.data.wpProductCategory.CollectionData.metaTitle
            : this.props.data.wpProductCategory.name,
          ogDescription: this.props.data.wpProductCategory.description,
          ogTitle: this.props.data.wpProductCategory.name,
          canonical: this.props.data.wpProductCategory.uri,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpProductCategory.PageCTA}
      >
        {isCategory && (
          <Category
            {...this.props.data.wpProductCategory}
            subcategories={subcategories}
            products={allProducts}
          />
        )}
        {isSubcategory && (
          <SubCategory
            {...this.props.data.wpProductCategory}
            subcategories={subcategories}
            products={allProducts}
          />
        )}
        <CookiesConsent></CookiesConsent>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    allWpProduct(
      filter: {
        status: { eq: "publish" }
        productCategories: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      nodes {
        id
        slug
        title
        uri
        modifiedGmt
        ProductData {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 720
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
          price
          featuredImage
          variants {
            sku
          }
        }
        ProductMeta {
          assemblyDifficulty
          assemblyTime
          bestseller
          warrantyPeriod
          delivery
          certification
          preservativeTreatment
          madeInBritain
          videos {
            url
          }
          pdfs {
            url
          }
        }
        brands {
          nodes {
            id
          }
        }
        productCategories {
          nodes {
            name
            id
          }
        }
      }
    }
    wpProductCategory(id: { eq: $id }) {
      id
      name
      description
      slug
      uri
      CollectionData {
        bannerImage {
          id
          uri
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1660
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        bannerTitle
        bannerText
        metaTitle
        metaDescription
      }
      wpChildren {
        nodes {
          id
          name
          uri
          products {
            nodes {
              id
            }
          }
          CollectionData {
            image {
              id
              uri
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 960
                    aspectRatio: 0.88
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
      wpParent {
        node {
          id
          name
          slug
          uri
          products {
            nodes {
              id
            }
          }
          wpChildren {
            nodes {
              id
              name
              uri
              products {
                nodes {
                  id
                }
              }
              wpChildren {
                nodes {
                  id
                  name
                  uri
                  products {
                    nodes {
                      id
                    }
                  }
                }
              }
            }
          }
          wpParent {
            node {
              id
            }
          }
        }
      }
    }
  }
`

export default Page
