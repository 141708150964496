const styles = theme => ({
  productGrid: {
    position: "relative",
    marginBottom: 32,
  },
  productList: {},
  results: {},
})

export default styles
