import React from "react"
// import { connect } from 'react-redux';
// import Img from "gatsby-image";
import Grid from "@material-ui/core/Grid"
// import Cookies from 'js-cookie';
import { withStyles } from "@material-ui/styles"
import styles from "./Products.styles"
import ProductCard from "./ProductCard"

class ProductsGrid extends React.Component {
  render() {
    const { classes, products } = this.props

    if (!products) {
      return <div>No products</div>
    }

    return (
      <Grid
        container
        spacing={3}
        className={"product-grid " + classes.productGrid}
      >
        {products &&
          products.map((product, index) => {
            if (!product) return null

            const { id, brands, title, uri } = product

            if (product.ProductData) {
              const price = product.ProductData.price
              const image = product.ProductData.localFile
              const {
                assemblyDifficulty,
                assemblyTime,
                warrantyPeriod,
                delivery,
                certification,
                preservativeTreatment,
                madeInBritain,
                videos,
                pdfs,
              } = product.ProductMeta
              return (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <ProductCard
                    key={id}
                    id={id}
                    uri={uri}
                    brands={brands}
                    title={title}
                    image={image}
                    featuredImage={product.ProductData.featuredImage}
                    price={price}
                    certification={certification}
                    assemblyEasy={assemblyDifficulty === "Easy"}
                    assemblyTime={assemblyTime}
                    warrantyPeriod={warrantyPeriod}
                    delivery={delivery}
                    preservativeTreatment={preservativeTreatment}
                    madeInBritain={madeInBritain}
                    videos={videos && videos.length > 0}
                    pdfs={pdfs}
                    skuCodes={product.ProductData.variants.map(v => v.sku)}
                  />
                </Grid>
              )
            } else {
              return (
                <Grid item xs={12} sm={6} lg={4}>
                  <ProductCard
                    key={id}
                    id={id}
                    uri={uri}
                    brands={brands}
                    title={title}
                  />
                </Grid>
              )
            }
          })}
      </Grid>
    )
  }
}

export default withStyles(styles)(ProductsGrid)
