import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Link from '../Link';
import styles from './styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => styles(theme));

export default function Breadcrumb(props) {
    const classes = useStyles();

    return (
        <div className={`breadcrumb ${classes.breadcrumb}`}>
            <div className="wrapper">
                <Breadcrumbs 
                    maxItems={2} 
                    itemsAfterCollapse={2}
                    itemsBeforeCollapse={0}
                    separator=""
                    aria-label="breadcrumbs"
                    className="mobile-breadcrumb"
                >
                    <Link to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <path id="noun_Home_2102808" d="M7.028,12.906l.327-.276v7.745A.625.625,0,0,0,7.98,21H19.02a.625.625,0,0,0,.625-.625V12.63l.327.276a.625.625,0,0,0,.806-.955l-3-2.533V7.039a.625.625,0,1,0-1.25,0V8.363L13.9,6.147a.625.625,0,0,0-.806,0l-6.875,5.8a.625.625,0,1,0,.806.955ZM12.1,19.75V15.238H14.9V19.75ZM13.5,7.443l4.895,4.132V19.75H16.147V14.613a.625.625,0,0,0-.625-.625H11.478a.625.625,0,0,0-.625.625V19.75H8.605V11.575Z" transform="translate(-6 -6)" fill="#4b3048"/>
                    </svg>
                    </Link>

                    {props.type === "page" && props.ancestors !== null && props.ancestors.nodes.map(link => {
                        return <Link to={link.uri} key={link.id}>{link.title}</Link>
                    })}

                    {props.type === 'category' && props.ancestors &&
                        <Link to={props.ancestors.uri}>{props.ancestors.name}</Link>
                    }

                    {props.type === "product" && props.ancestors !== null && props.ancestors.map(link => {
                        return <Link to={link.uri} key={link.id}>{link.name}</Link>
                    })}

                    {props.type === "case-study" && <Link to="/trade/">Trade</Link> }

                    {props.type === "case-study" && <Link to="/trade/company-news/">Company News</Link> }

                    {props.type === "post" &&
                        <Link to="/ideas-advice/">Ideas & Advice</Link>
                    }

                    <Typography>{props.current.title}</Typography>
                </Breadcrumbs>
                <Breadcrumbs 
                    maxItems={4} 
                    itemsAfterCollapse={4}
                    itemsBeforeCollapse={0}
                    separator=""
                    aria-label="breadcrumbs"
                    className="tablet-breadcrumb"
                >
                    <Link to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <path id="noun_Home_2102808" d="M7.028,12.906l.327-.276v7.745A.625.625,0,0,0,7.98,21H19.02a.625.625,0,0,0,.625-.625V12.63l.327.276a.625.625,0,0,0,.806-.955l-3-2.533V7.039a.625.625,0,1,0-1.25,0V8.363L13.9,6.147a.625.625,0,0,0-.806,0l-6.875,5.8a.625.625,0,1,0,.806.955ZM12.1,19.75V15.238H14.9V19.75ZM13.5,7.443l4.895,4.132V19.75H16.147V14.613a.625.625,0,0,0-.625-.625H11.478a.625.625,0,0,0-.625.625V19.75H8.605V11.575Z" transform="translate(-6 -6)" fill="#4b3048"/>
                    </svg>
                    </Link>

                    {props.type === "page" && props.ancestors !== null && props.ancestors.nodes.map(link => {
                        return <Link to={link.uri} key={link.id}>{link.title}</Link>
                    })}

                    {props.type === 'category' && props.ancestors &&
                        <Link to={props.ancestors.uri}>{props.ancestors.name}</Link>
                    }

                    {props.type === "product" && props.ancestors !== null && props.ancestors.map(link => {
                        return <Link to={link.uri} key={link.id}>{link.name}</Link>
                    })}

                    {props.type === "case-study" && <Link to="/trade/">Trade</Link> }

                    {props.type === "case-study" && <Link to="/trade/company-news/">Company News</Link> }

                    {props.type === "post" &&
                        <Link to="/ideas-advice/">Ideas & Advice</Link>
                    }

                    <Typography>{props.current.title}</Typography>
                </Breadcrumbs>
                <Breadcrumbs 
                    itemsBeforeCollapse={0}
                    separator=""
                    aria-label="breadcrumbs"
                    className="desktop-breadcrumb"
                >
                    <Link to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <path id="noun_Home_2102808" d="M7.028,12.906l.327-.276v7.745A.625.625,0,0,0,7.98,21H19.02a.625.625,0,0,0,.625-.625V12.63l.327.276a.625.625,0,0,0,.806-.955l-3-2.533V7.039a.625.625,0,1,0-1.25,0V8.363L13.9,6.147a.625.625,0,0,0-.806,0l-6.875,5.8a.625.625,0,1,0,.806.955ZM12.1,19.75V15.238H14.9V19.75ZM13.5,7.443l4.895,4.132V19.75H16.147V14.613a.625.625,0,0,0-.625-.625H11.478a.625.625,0,0,0-.625.625V19.75H8.605V11.575Z" transform="translate(-6 -6)" fill="#4b3048"/>
                    </svg>
                    </Link>

                    {props.type === "page" && props.ancestors !== null && props.ancestors.nodes.map(link => {
                        return <Link to={link.uri} key={link.id}>{link.title}</Link>
                    })}

                    {props.type === 'category' && props.ancestors &&
                        <Link to={props.ancestors.uri}>{props.ancestors.name}</Link>
                    }

                    {props.type === "product" && props.ancestors !== null && props.ancestors.map(link => {
                        return <Link to={link.uri} key={link.id}>{link.name}</Link>
                    })}

                    {props.type === "case-study" && <Link to="/trade/">Trade</Link> }

                    {props.type === "case-study" && <Link to="/trade/company-news/">Company News</Link> }

                    {props.type === "post" &&
                        <Link to="/ideas-advice/">Ideas & Advice</Link>
                    }

                    <Typography>{props.current.title}</Typography>
                </Breadcrumbs>
            </div>
        </div>
    )
}