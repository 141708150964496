import React from "react"
import ReactHtmlParser from "react-html-parser"
import PropTypes from "prop-types"
import { transformLink } from "../../../helpers/"
import { graphql } from "gatsby"
import Paragraph from "../../Paragraph"

export const CoreParagraphBlock = props => {
  if (props.attributes.content === "" || props.attributes.content === null)
    return null

  const { align, textColor, backgroundColor } = props.attributes

  return (
    <Paragraph
      className={`${props.attributes.fontSize} ${props.attributes.className}`}
      align={align}
      textColor={textColor}
      backgroundColor={backgroundColor}
      dropCap={props.attributes.dropCap}
    >
      {props.attributes.preParagraph}
      {ReactHtmlParser(props.attributes.content, { transform: transformLink })}
    </Paragraph>
  )
}

CoreParagraphBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreParagraphBlockFragment = graphql`
  fragment CoreParagraphBlock on WpBlock {
    ... on WpCoreParagraphBlock {
      name
      attributes {
        __typename
        ... on WpCoreParagraphBlockAttributes {
          backgroundColor
          textColor
          className
          align
          direction
          content
          fontSize
          dropCap
        }
      }
    }
  }
`

export const CoreParagraphBlockPreview = `
... on CoreParagraphBlock {
  name
  attributes {
    __typename
    ... on CoreParagraphBlockAttributes {
      backgroundColor
      textColor
      className
      align
      direction
      content
      fontSize
      dropCap
    }
  }
}
`
