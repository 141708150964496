import React from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles"
import styles from "./SubCategoryNav.styles"

import { setSort, setPage } from "../../../app/actions"

const useStyles = makeStyles(theme => styles(theme))

const mapDispatchToProps = dispatch => ({
  setSortType: sort => dispatch(setSort(sort)),
  setPage: page => dispatch(setPage(page)),
})

const mapStateToProps = state => ({
  sort: state.ShopSettings.sort,
})

const connector = connect(mapStateToProps, mapDispatchToProps)

function SubcategoryNav({ products, sort, setSortType, itemsToShow, setPage }) {
  const [open] = React.useState(false)

  // const showMore = () => {
  //   setOpen(!open)
  // }

  function setSortHandler(value) {
    setSortType(value)
    setPage(1)
  }

  const classes = useStyles()

  return (
    <div className={classes.subCatNav}>
      <span className="count">
        <strong>Products:</strong> {products.length}{" "}
        {products.length === 1 ? "item" : "items"}
      </span>
      {/* <button
        className="trigger"
        onClick={showMore}
        onKeyDown={showMore}
        open={open}
      >
        Sort
      </button> */}
      <div className="sort-wrap" open={open}>
        <div className="select-row">
          <span>Sort by</span>
          <select
            defaultValue={itemsToShow}
            onChange={e => setSortHandler(e.target.value)}
          >
            <option value="best">Best sellers</option>
            <option value="price-htl">Price (High to Low)</option>
            <option value="price-lth">Price (Low to High)</option>
            <option value="newest">Newest</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default connector(SubcategoryNav)
