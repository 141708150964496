export default theme => ({ // eslint-disable-line
    button: {
        fontSize: 18,
        fontFamily: theme.bodyFont,
        display: 'inline-table',
        marginLeft: 12,
        marginRight: 16,
        marginBottom: 16,
        maxWidth: 1118,
        padding: '16px 24px',
        position: 'relative',
        fontWeight: 'bold',
        lineHeight: 1.33,
        borderRadius: theme.border.radius,
        [theme.breakpoints.up(1128)]: {
            marginLeft: 'calc(50% - 560px)',
        },
        '&.centred': {
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
        },
        '& span': {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        '& svg': {
            width: 36,
            height: 36,
            display: 'inline-block',
            verticalAlign: 'middle',
            marginLeft: 10,
            marginTop: -6,
            marginBottom: -6,
        },
        '&.filled-green': {
            color: theme.palette.primary.navy,
            backgroundColor: theme.palette.primary.green,
            boxShadow: theme.shadow,
            transition: 'all .3s ease-in-out',
            '& svg': {
                marginLeft: 0,
                marginRight: -10,
                '& .circ': { fill: 'transparent', },
                '& .chev': { 
                    fill: theme.palette.primary.navy ,
                    transition: 'all .3s ease-in-out', 
                },
            },
            '&:hover, &:focus': {
                color: theme.palette.primary.green,
                backgroundColor: theme.palette.primary.navy,
                '& svg': {
                    '& .chev': { fill: theme.palette.primary.green , },
                },
            }
        },
        '&.filled-purple': {
            color: theme.palette.secondary.cream,
            backgroundColor: theme.palette.primary.purple,
            boxShadow: theme.shadow,
            transition: 'all .3s ease-in-out',
            '& svg': {
                marginLeft: 0,
                marginRight: -10,
                '& .circ': { fill: 'transparent', },
                '& .chev': { 
                    fill: theme.palette.secondary.cream ,
                    transition: 'all .3s ease-in-out',  
                },
            },
            '&:hover, &:focus': {
                color: theme.palette.primary.purple,
                backgroundColor: theme.palette.secondary.cream,
                '& svg': {
                    '& .chev': { fill: theme.palette.primary.purple , },
                },
            }
        },
        '&.transparent-purple': {
            color: theme.palette.primary.purple,
            paddingLeft: 0,
            '& svg': {
                '& .circ': { fill: theme.palette.primary.purple, },
                '& .chev': { fill: theme.palette.secondary.cream , },
            }
        },
        '&.transparent-green': {
            color: theme.palette.primary.green,
            paddingLeft: 0,
            '& svg': {
                '& .circ': { fill: theme.palette.primary.green, },
                '& .chev': { fill: theme.palette.primary.navy , },
            }
        },
        '&.transparent-white': {
            color: theme.palette.secondary.cream,
            paddingLeft: 0,
            '& svg': {
                '& .circ': { fill: theme.palette.secondary.cream, },
                '& .chev': { fill: theme.palette.primary.navy , },
            }
        },
    },
})