import React from "react"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"

export default function ProductsList({ products }) {
  if (!products) {
    return <div>No products</div>
  }

  return (
    <Grid container spacing={3}>
      {products.nodes.map((product, index) => {
        return (
          <Grid item xs={12} key={index}>
            <button>Wishlist</button>
            <Link to={product.link}>
              <img
                src={product.product.productGallery[0].sourceUrl}
                alt=""
                loading="lazy"
              />
              {product.title}
            </Link>
          </Grid>
        )
      })}
    </Grid>
  )
}
