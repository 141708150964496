export default theme => ({ // eslint-disable-line
    latestProducts: {
      
        
        '& .slick-slider': {
            flex: '0 0 100%',
            maxWidth: '100%',
            marginBottom: 120,
            [theme.breakpoints.up('md')]: {
                marginBottom: 150,
            },
            '& .slick-list': {
                overflow: 'visible',
                width: '92%',
                [theme.breakpoints.up('lg')]: {
                    width: '100%',
                },
                '& .slick-track': {
                  marginLeft: 0,
                }
            },
            '& .slick-slide': {
                padding: 12,
            },
            '& .slick-arrow': {
                width: 36,
                height: 36,
                textAlign: 'center',
                color: theme.common.white,
                fontSize: 0,
                display: 'inline-block',
                position: 'absolute',
                transition: 'margin-left .3s ease-in-out',
                top: 'calc(100% + 30px)',
                transform: 'none',
                [theme.breakpoints.up('md')]: {
                    width: 54,
                    height: 54,
                },
                '&::before': { display: 'none' },
                '&.slick-prev': {
                  right: 'calc(50% + 100px)',
                  left: 'auto',
                  '& svg': {
                      transform: 'scaleX(-1)',
                  }
                },
                '&.slick-next': {
                  left: 'calc(50% + 100px)',
                  right: 'auto',
                },
                '& svg': {
                    width: 36,
                    height: 36,
                    [theme.breakpoints.up('md')]: {
                        width: 54,
                        height: 54,
                    },
                    '& .circ': { fill: theme.palette.primary.navy},
                    '& .chev': { fill: theme.palette.secondary.cream},
                },
                '&.slick-disabled': {
                    '& svg': {
                        '& .circ': { fill: 'transparent', stroke: theme.palette.primary.navy, strokeWidth: 2 },
                        '& .chev': { fill: theme.palette.primary.navy},
                    }
                }
              },
              '& .slick-dots': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                left: 'calc(50% - 75px)',
                right: 'auto',
                height: 30,
                width: 150,
                top: 'calc(100% + 30px)',
                [theme.breakpoints.up('md')]: {
                    top: 'calc(100% + 42px)',
                },
                '& li': {
                  margin: 4,
                  width: 'auto',
                  height: 'auto',
                  '& button': {
                    width: 12,
                    height: 12,
                    borderRadius: 6,
                    boxShadow: 'inset 0 0 0 1px ' + theme.palette.background.default,
                    border: '1px solid ' + theme.palette.primary.navy,
                    backgroundColor: 'transparent',
                    display: 'block',
                    padding: 0,
                    margin: 0,
                    transition: 'background-color .3s ease-in-out',
                    '&::before': {
                      display: 'none',
                    }
                  },
                  '&.slick-active': {
                    '& button': {
                        backgroundColor: theme.palette.primary.navy,
                    }
                  }
                }
              }
            }
          }
        
});