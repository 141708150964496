import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./CategoryBanner.styles"
import ReactHtmlParser from 'react-html-parser';
import PreviewImage from "../../PreviewImage";

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock"
import { CoreParagraphBlock } from "../../blocks/CoreParagraphBlock"

const useStyles = makeStyles(theme => styles(theme))

function CategoryBanner({data}) {

  const classes = useStyles()

  if(data.bannerImage !== null) {
    return (
      <div className={`${classes.catBanner}`}>
          {data.bannerImage !== null && 
            <PreviewImage 
                image={data.bannerImage.localFile} 
                fallbackUrl={data.bannerImage.mediaUrl} 
                alt="" 
                fixedPadding="60%"
                className="desktop-image"
            />
          }
          <div className="wrapper">
            <div className="con-wrap">
              <CoreHeadingBlock 
                  attributes={{
                    textAlign: "left",
                    anchor: "",
                    className: "",
                    content: ReactHtmlParser(data.bannerTitle),
                    level: 3,
                    textColor: "initial",
                    backgroundColor: "",
                    __typename: "WpCoreHeadingBlockAttributes",
                  }}
                  innerBlocks={[]} 
              />
              <CoreParagraphBlock 
                  attributes={{
                    align: "left",
                    className: "",
                    content: ReactHtmlParser(data.bannerText),
                    textColor: "initial",
                    backgroundColor: "",
                    fontSize: "",
                    __typename: "WpCoreParagraphBlockAttributes",
                  }}
                  innerBlocks={[]} 
              /> 
              </div>
          </div>
      </div>
    )
  } else {
    return null;
  }
}

export default CategoryBanner
