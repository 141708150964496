import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./CategoryTitle.styles"
import ReactHtmlParser from 'react-html-parser';

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock"
import { CoreParagraphBlock } from "../../blocks/CoreParagraphBlock"

const useStyles = makeStyles(theme => styles(theme))

function CategoryTitle({title, description}) {

  const classes = useStyles()

  return (
    <div className={classes.catTitle}>
      <div className="wrapper">
        <CoreHeadingBlock 
            attributes={{
              textAlign: "center",
              anchor: "",
              className: "",
              content: ReactHtmlParser(title),
              level: 1,
              textColor: "initial",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]} 
        />
        <CoreParagraphBlock 
            attributes={{
              align: "center",
              className: "post-excerpt",
              content: ReactHtmlParser(description),
              textColor: "initial",
              backgroundColor: "",
              fontSize: "",
              __typename: "WpCoreParagraphBlockAttributes",
            }}
            innerBlocks={[]} 
        />   
      </div>
    </div>
  )
}

export default CategoryTitle
