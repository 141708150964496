import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styles from "./SubCategoryGrid.styles"

import PreviewImage from "../../PreviewImage"
import ArrowSvg from "../../ArrowSvg"

const useStyles = makeStyles(theme => styles(theme))

export default function SubcategoryGrid({ subcategories }) {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query categoryFallbackImage {
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
              menuLinkImage {
                id
                uri
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 960
                      aspectRatio: 0.88
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Grid className={classes.subCatGrid} container spacing={3}>
      {subcategories.nodes.map((subcategory, index) => {
        return (
          <Grid item sm={6} md={4} key={index}>
            <Link to={subcategory.uri} className="link-item">
              {subcategory.CollectionData.image !== null && (
                <PreviewImage
                  image={subcategory.CollectionData.image.localFile}
                  fallbackUrl={subcategory.CollectionData.image.uri}
                  alt={subcategory.name}
                  fixedPadding="100%"
                />
              )}
              {subcategory.CollectionData.image === null && (
                <PreviewImage
                  image={
                    data.wp.themeOptions.ThemeOptions.defaultImages
                      .menuLinkImage.localFile
                  }
                  fallbackUrl={
                    data.wp.themeOptions.ThemeOptions.defaultImages
                      .menuLinkImage.uri
                  }
                  alt={subcategory.name}
                  fixedPadding="100%"
                />
              )}
              <span className="name">{subcategory.name}</span>
              <ArrowSvg />
            </Link>
          </Grid>
        )
      })}
    </Grid>
  )
}
