import React from 'react';
import { connect } from 'react-redux';

import SubCategoryGrid from '../Components/SubCategoryGrid';
import CategoryTitle from '../Components/CategoryTitle';
import Breadcrumb from '../../Breadcrumb';
import CategoryBanner from '../Components/CategoryBanner';
import LatestProducts from '../Components/LatestProducts';
import { useStaticQuery, graphql } from "gatsby";

import { AcfAdvertBlock } from "../../blocks/AcfAdvertBlock";

const mapStateToProps = (state) => ({
  layout: state.ShopSettings.layout
});

// const useStyles = makeStyles(theme => styles(theme));

const connector = connect(mapStateToProps, null);

function Category({ uri, slug, name, description, wpChildren, wpParent, CollectionData }) {
  // const classes = useStyles();

  const data = useStaticQuery(graphql`
  query showCatPage {
    wp {
      themeOptions {
        ThemeOptions {
          shopCategoryAdvertBlock {
            ... on WpAdvert {
              Advert {
                title
                body
                ctaText
                ctaUrl
                image {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`);

  return <>
    <Breadcrumb
      type="category"
      ancestors={wpParent && wpParent.node}
      current={{
        title: name,
        slug: slug,
        uri: uri
      }}
    />
    <CategoryTitle title={name} description={description} />
    <div className="wrapper" style={{padding: '0 12px'}}>
      <SubCategoryGrid subcategories={wpChildren} />
    </div>
    {data.wp.themeOptions.ThemeOptions.shopCategoryAdvertBlock !== null &&
      <>
        <AcfAdvertBlock
          attributes={{
            className: "",
            name: "acf/advert"
          }}
          block={{
            attributes: {
              className: "",
              name: "acf/advert"
            },
            blockAdvert: {
              advert: data.wp.themeOptions.ThemeOptions.shopCategoryAdvertBlock
            },
            innerBlocks: []
          }}
          innerBlocks={[]}
        />
        <br/>
        <br/>
      </>
    }
    <CategoryBanner data={CollectionData} />
    <LatestProducts header="Latest Products"/>
  </>
}

export default connector(Category);