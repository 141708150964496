export default theme => ({ // eslint-disable-line
  key: {
    '&.mobile': {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      }
    },
    '&.desktop': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      }
    },
    marginTop: 32,
    "& h4": {
      fontFamily: theme.headerFont,
      letterSpacing: "0.008em",
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: 1,
      margin: 0,
      color: theme.palette.primary.purple,
    },
    "& h5": {
      marginTop: 16,
      paddingBottom: 4,
      borderBottom: "2px solid " + theme.palette.secondary.beige,
      color: theme.palette.primary.purple,
      fontSize: 12,
      fontWeight: "bold",
      letterSpacing: "0.016em",
      fontFamily: theme.bodyFont,
    },
    "& .key-item": {
      display: "flex",
      width: "100%",
      margin: "4px 0",
      alignItems: "center",
      "& img": {
        width: 40,
        marginRight: 16,
      },
      "& span": {
        color: theme.palette.primary.navy,
        letterSpacing: "0.016em",
        fontFamily: theme.bodyFont,
        fontWeight: "bold",
        fontSize: 12,
        fontWright: "bold",
      },
    },
  },
})
