import React from "react"
// import { navigate } from "gatsby";
import { Link } from "gatsby"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles"
import styles from "./SideNav.styles"
import ReactHtmlParser from "react-html-parser"
import { transformLink } from "../../../helpers/"

import { setFilter } from "../../../app/actions"

import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"

import Key from "./Key"

const useStyles = makeStyles(theme => styles(theme))

const mapStateToProps = state => ({
  shop: state.ShopSettings,
  filters: state.ShopSettings.filter,
})

const mapDispatchToProps = dispatch => ({
  setFilter: (prevFilters, filter) => dispatch(setFilter(prevFilters, filter)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

function SideNav({
  name,
  id,
  description,
  uri,
  parent,
  subcategories,
  products,
  setFilter,
  shop,
  filters,
  siblings,
  total,
}) {
  const [open, setOpen] = React.useState(false)
  const [catOpen, setCatOpen] = React.useState(false)
  // const [query, setQuery] = useState('');

  const classes = useStyles()

  const showMore = () => {
    setOpen(!open)
  }

  const showCats = () => {
    setCatOpen(!catOpen)
  }

  // function handleSubmit(e) {
  //   if (e) e.preventDefault(e);
  //   if (!query) return;

  //   navigate('/product-search', { state: { query } });
  // }

  // function filterHandler(e) {
  //   setFilter(shop.filter, { [e.target.value]: e.target.checked });
  // }

  const isCategory = !parent && subcategories && !!subcategories.length
  const isSubcategory = parent && !parent.node.wpParent
  const isSubSubCategory = parent && !subcategories.length

  if (isCategory) {
    return (
      <aside className={classes.sideNav}>
        <div className="filter-block">
          <button
            className={`expand-title ${!isCategory ? "border" : ""}`}
            onClick={showCats}
            onKeyDown={showCats}
            open={catOpen}
          >
            Categories
          </button>
          <div className="filter-block-expand" open={catOpen}>
            <div className="categories">
              <ul>
                {subcategories.map((subcategory, index) => {
                  return (
                    <li key={index}>
                      <Link to={subcategory.uri}>
                        <span
                          className={`${id === subcategory.id ? "active" : ""}`}
                        >
                          {subcategory.name}
                        </span>
                        <ChevronRightIcon />
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <Key showFor="desktop" />
      </aside>
    )
  }

  if (isSubcategory) {
    // const fallbackLength = parent.node.wpChildren.nodes.find(
    //   node => node.id === id
    // ).products.nodes.length
    return (
      <aside className={classes.sideNav}>
        <div className="filter-block">
          <button
            className="expand-title"
            onClick={showCats}
            onKeyDown={showCats}
            open={catOpen}
          >
            Categories
          </button>
          <div className="filter-block-expand" open={catOpen}>
            <div className="current">
              {parent && (
                <span>
                  <Link to={parent.node.uri}>
                    <ChevronLeftIcon /> See all {parent.node.name}
                  </Link>
                </span>
              )}
            </div>

            <div className="categories sub">
              <ul>
                {siblings.map((subcategory, index) => {
                  return (
                    <li key={index}>
                      <Link to={subcategory.uri}>
                        <span
                          className={`${subcategory.id === id ? "active" : ""}`}
                        >
                          {subcategory.name}
                        </span>
                        <span className="total">
                          {subcategory.products.length}
                        </span>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div></div>
        <Key showFor="desktop" />
      </aside>
    )
  }

  if (isSubSubCategory) {
    return (
      <aside className={classes.sideNav}>
        <div className="filter-block">
          <button
            className="expand-title"
            onClick={showCats}
            onKeyDown={showCats}
            open={catOpen}
          >
            Categories
          </button>
          <div className="filter-block-expand" open={catOpen}>
            <div className="current">
              {parent && (
                <span>
                  <Link to={parent.node.uri}>
                    <ChevronLeftIcon /> See all {parent.node.name}
                  </Link>
                </span>
              )}
            </div>

            <div className="categories sub">
              <ul>
                {siblings.map((subcategory, index) => {
                  return (
                    <li key={index}>
                      <Link to={subcategory.uri}>
                        {subcategory.name}
                        <span className="total">
                          {subcategory.products.length}
                        </span>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div></div>
        <Key showFor="desktop" />
      </aside>
    )
  }

  return (
    <aside className={classes.sideNav}>
      <h2>{name}</h2>
      <p open={open}>
        {ReactHtmlParser(description, { transform: transformLink })}
      </p>
      <button className="more" onClick={showMore} onKeyDown={showMore}>
        Read
      </button>
      <div className="filter-block">
        <button
          className="expand-title"
          onClick={showCats}
          onKeyDown={showCats}
          open={catOpen}
        >
          Categories
        </button>
        <div className="filter-block-expand" open={catOpen}>
          <div className="current">
            {parent && (
              <span>
                See all <Link to={parent.node.uri}>{parent.node.name} </Link>
              </span>
            )}
          </div>

          <div className="categories">
            <ul>
              <li>
                <Link to={uri}>
                  <span className="active">{name}</span>
                  <span className="total">
                    {total ? total : products.length}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div></div>
      <Key showFor="desktop" />
    </aside>
  )
}

export default connector(SideNav)
