import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./Key.styles"

import key1 from "../../../images/key-fsc-cert.webp"
import pefc from "../../../images/key-pefc-cert.webp"
import key2 from "../../../images/key-warranty.webp"
import key3 from "../../../images/key-pressure-treated.webp"
import key4 from "../../../images/key-dip-treated.webp"
import key5 from "../../../images/key-ready-built.webp"
import key6 from "../../../images/key-flat-packed.webp"
import key7 from "../../../images/key-easy-build.webp"
import key8 from "../../../images/key-assembly-video.webp"
// import key9 from "../../../images/key-delivery.png"
import key10 from "../../../images/key-12mg.webp"
import key11 from "../../../images/key-made-in-britain.webp"

const useStyles = makeStyles(theme => styles(theme))

function Key(props) {
  const classes = useStyles()

  return (
    <div className={classes.key + " " + props.showFor}>
      <h4>Key Index</h4>
      <h5>Quality</h5>
      <div className="key-item">
        <img width="24" height="24" loading="lazy" src={key1} alt="FSC Certified" />{" "}
        <span>
          FSC<sup>®</sup> Certified
        </span>
      </div>
      <div className="key-item">
        <img loading="lazy" src={pefc} alt="PEFC Certified" />
        <span>PEFC Certified</span>
      </div>
      <div className="key-item">
        <img loading="lazy" width="24" height="24" src={key2} alt="10 Year Warranty" />{" "}
        <span>10 Year Warranty</span>
      </div>
      <div className="key-item">
        <img width="24" height="24" src={key3} alt="Pressure Treted" loading="lazy" />{" "}
        <span>Pressure Treated</span>
      </div>
      <div className="key-item">
        <img width="24" height="24" src={key4} alt="Dip Treated" loading="lazy" />{" "}
        <span>Dip Treated</span>
      </div>
      <div className="key-item">
        <img width="24" height="24" src={key11} alt="Made in Britain" loading="lazy" />{" "}
        <span>Made in Britain</span>
      </div>
      <h5>Assembly</h5>
      <div className="key-item">
        <img width="24" height="24" src={key5} alt="Ready Built" loading="lazy" />{" "}
        <span>Ready Built</span>
      </div>
      <div className="key-item">
        <img width="24" height="24" src={key6} alt="Flat Packed" loading="lazy" />{" "}
        <span>Flat Packed</span>
      </div>
      <div className="key-item">
        <img width="24" height="24" src={key7} alt="Easy Build" loading="lazy" />{" "}
        <span>Easy Build</span>
      </div>
      <div className="key-item">
        <img width="24" height="24" src={key8} alt="Assembly Instructions" loading="lazy" />{" "}
        <span>Assembly Instructions</span>
      </div>
    </div>
  )
}

export default Key
