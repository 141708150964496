export default theme => ({ // eslint-disable-line
  sideNav: {
    marginTop: 24,
    marginBottom: 24,
    [theme.breakpoints.up("md")]: {
      marginTop: 50,
    },
    "& .filter-block": {
      backgroundColor: "#E4DFD9",
      borderRadius: 4,
      padding: 20,
      "& .expand-title": {
        fontFamily: theme.headerFont,
        color: theme.palette.primary.purple,
        letterSpacing: "0.008em",
        lineHeight: 1.5,
        fontWeight: "bold",
        display: "block",
        fontSize: 16,
        backgroundColor: "transparent",
        border: "none",
        cursor: 'default',
        width: 'calc(100% + 40px)',
        textAlign: 'left',
        margin: -20,
        padding: 20,
        position: 'relative',
        '&:after': {
          content: "''",
          display: 'block',
          width: 8,
          height: 8,
          borderBottom: '2px solid ' + theme.palette.primary.purple,
          borderLeft: '2px solid ' + theme.palette.primary.purple,
          position: 'absolute',
          right: 40,
          transform: 'rotate(-45deg)',
          top: 26,
          [theme.breakpoints.up("md")]: {
            display: 'none',
          }
        }
      },
      "& .filter-block-expand": {
        height: 0,
        overflow: "hidden",
        transition: "opacity .3s ease-in-out",
        opacity: 0,
        [theme.breakpoints.up("md")]: {
          height: "auto !important",
          opacity: "1 !important",
        },
        "&[open]": {
          opacity: 1,
          height: "auto",
        },
        "& .current a": {
          display: "block",
          marginTop: 20,
          backgroundColor: "#FCF9F5",
          borderRadius: 4,
          padding: 16,
          fontSize: 14,
          fontWeight: "bold",
          color: theme.palette.primary.navy,
          fontFamily: theme.bodyFont,
          lineHeight: 1.4,
          "& svg": {
            width: 14,
            height: 19,
            marginRight: 0,
            verticalAlign: "bottom",
          },
        },
        "& .categories": {
          "& ul": {
            display: "block",
            margin: 0,
            padding: 0,
            "& li": {
              display: "block",
              [theme.breakpoints.up("md")]: {},
              "& a": {
                padding: "20px 16px",
                borderTop: "1px solid rgba(0,0,0,0.05)",
                fontSize: 14,
                letterSpacing: "0.025em",
                lineHeight: 1.5,
                textDecoration: "none",
                position: "relative",
                display: "block",
                fontFamily: theme.bodyFont,
                color: theme.palette.primary.navy,
                "& .active": {
                  display: "inline-block",
                  fontWeight: "bold",
                },
              },
            },
          },
          "& .subsubcategory": {
            paddingLeft: 14,
          },
          "&.sub": {
            "& ul": {
              margin: "12px 0",
            },
            "& ul li a": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: "normal",
              paddingTop: 12,
              paddingBottom: 12,
              borderTop: "none",
            },
          },
        },
      },
    },
    "& .product-search": {
      borderRadius: 6,
      marginTop: 32,
      marginBottom: 32,
      position: "relative",
      paddingBottom: 1,
      "& label": {
        border: "none",
        fontFamily: theme.bodyFont,
        fontSize: 18,
        display: "block",
        outline: "none",
        width: "100%",
        backgroundColor: "transparent",
        textAlign: "left",
        padding: "24px 20px",
        fontWeight: 500,
        position: "relative",
        textDecoration: "none",
        [theme.breakpoints.up("md")]: {
          padding: 30,
          textDecoration: "none",
        },
      },
      '& input[type="text"]': {
        borderRadius: 28,
        padding: "20px 60px 20px 12px",
        display: "block",
        width: "calc(100% - 14px)",
        lineHeight: 1,
        fontSize: 12,
        backgroundColor: "transparent",
        fontFamily: theme.bodyFont,
        letterSpacing: "0.032em",
        outline: "none",
        margin: "0 7px 20px",
        "& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder": {
          opacity: 1,
        },
        [theme.breakpoints.up("md")]: {
          width: "calc(100% - 34px)",
          margin: " 0 17px 30px",
        },
      },
      '& input[type="submit"]': {
        position: "absolute",
        outline: "none",
        bottom: 34,
        right: 17,
        width: 32,
        height: 32,
        border: "none",
        backgroundColor: "transparent",
        borderRadius: "50%",
        fontSize: 0,
        backgroundPosition: "center",
        //backgroundImage: 'url('+icon+')',
        backgroundSize: "cover",
        [theme.breakpoints.up("md")]: {
          bottom: 44,
          right: 27,
        },
      },
    },
    "& .side-key": {
      marginTop: 32,
      "& h4": {
        fontFamily: theme.headerFont,
        letterSpacing: "0.008em",
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1,
        margin: 0,
        color: theme.palette.primary.purple,
      },
      "& h5": {
        marginTop: 16,
        paddingBottom: 4,
        borderBottom: "2px solid " + theme.palette.secondary.beige,
        color: theme.palette.primary.purple,
        fontSize: 12,
        fontWeight: "bold",
        letterSpacing: "0.016em",
        fontFamily: theme.bodyFont,
      },
      "& .key-item": {
        display: "flex",
        width: "100%",
        margin: "4px 0",
        alignItems: "center",
        "& img": {
          width: 40,
          marginRight: 16,
        },
        "& span": {
          color: theme.palette.primary.navy,
          letterSpacing: "0.016em",
          fontFamily: theme.bodyFont,
          fontWeight: "bold",
          fontSize: 12,
          fontWright: "bold",
        },
      },
    },
  },
  pagination: {
    justifyContent: "center",
    position: "relative",
    margin: "36px 0",
    bottom: 0,
    display: "flex",
    "& li": {
      display: "block",
      "& a": {
        fontWeight: 500,
        margin: "0 10px",
        borderRadius: 0,
        transition: "opacity .3s ease-in-out",
        backgroundColor: "transparent !important",
        border: "none",
        outline: "none",
        fontFamily: theme.bodyFont,
        fontSize: 18,
        padding: "0 6px",
        width: "auto",
        height: "auto",
        display: "block",
        textAlign: "center",
        paddingTop: 12,
        "&:hover, &:focus": {
          opacity: ".8",
        },
        "& span": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
        "&.active": {
          //backgroundImage: 'url('+currentPage+')',
          backgroundPosition: "center",
          width: 58,
          height: 80,
          paddingTop: 37,
          marginTop: -25,
          marginBottom: -5,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
})
