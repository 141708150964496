export default theme => ({ // eslint-disable-line
  advertBlock: {
    backgroundColor: "var(--primary-navy)",
    display: "flex",
    flexDirection: "column-reverse",
    width: "calc(100% - 24px)",

    borderRadius: 12,
    borderBottom: "12px solid var(--primary-green)",
    margin: 12,

    [theme.breakpoints.up("sm")]: {
      borderLeft: "12px solid var(--primary-green)",
      justifyContent: "space-between",
      flexDirection: "row",
      padding: 0,
      borderBottom: "none",
      margin: "12px auto",
    },

    "& .advert-heading-body-and-button": {
      justifyContent: "space-between",
      margin: 24,
      [theme.breakpoints.up("sm")]: {
        margin: 56,
      },
      "& h3": {
        marginBottom: 18,

        padding: 0,
      },
      "& p": {
        margin: "16px 0",
        padding: 0,
      },
      "& a": {
        color: "var(--primary-green)",
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: "bold",
        lineHeight: 1.333,
        fontFamily: "var(--body-font)",
        boxShadow: "var(--box-shadow)",
        margin: 0,
        padding: 0,
      },
    },
    "& .image-wrapper": {
      overflow: "hidden",
      borderRadius: 12,
      flex: "0 0 30%",
      "& .gatsby-image-wrapper": {
        display: 'none',
        height: "100%",
        [theme.breakpoints.up("sm")]: {
          display: 'block',
        },
        '& img': {
          objectFit: 'contain !important',
        }
      },

      [theme.breakpoints.up("sm")]: {
        margin: 12,
      },
    },
  },
})
