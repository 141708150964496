import arrow from "../../../images/select-arrow.webp"

const styles = theme => ({
  subCatNav: {
    position: "relative",
    marginBottom: 30,
    [theme.breakpoints.up("md")]: {
      marginTop: 50,
      borderBottom: "none",
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .count": {
      fontSize: 16,
      letterSpacing: "0.016em",
      lineHeight: 2,
      fontWeight: "normal",
      color: theme.palette.primary.navy,
      fontFamily: theme.bodyFont,
    },
    "& button.trigger": {
      padding: 0,
      border: "none",
      backgroundColor: "transparent",
      textDecoration: "underline",
      fontSize: 12,
      fontWeight: 400,
      textTransform: "uppercase",
      letterSpacing: "0.016em",
      lineHeight: 1,
      fontFamily: theme.bodyFont,
      float: "right",
      outline: "none",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      "&::before": {
        content: '""',
        width: 22,
        height: 13,
        //backgroundImage: 'url(' + sortArrows + ')',
        backgroundPosition: "center",
        display: "inline-block",
        marginRight: 5,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
    },
    "& .sort-wrap": {
      backgroundColor: theme.common.white,
      marginTop: 12,
      // overflow: 'hidden',
      // height: 0,
      // opacity: 0,
      // transition: 'opacity .3s ease-in-out',
      // '&[open]': {
      //   height: 'auto',
      //   opacity: 1,
      // },
      [theme.breakpoints.up("md")]: {
        height: "auto !important",
        opacity: "1 !important",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "row",
        marginTop: 0,
      },
      "& .select-row": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "12px 12px 0",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          padding: 12,
        },
        "& span": {
          fontSize: 12,
          fontFamily: theme.bodyFont,
          color: theme.palette.primary.navy,
          letterSpacing: "0.152em",
          lineHeight: "32px",
          textTransform: "uppercase",
          fontWeight: "bold",
          marginRight: 12,
        },
        "& select": {
          borderRadius: 4,
          color: "#454545",
          border: "1px solid " + theme.palette.secondary.beige,
          fontSize: 16,
          cursor: "pointer",
          letterSpacing: "0.016em",
          padding: "17px 45px 17px 20px",
          lineHeight: 1.1,
          fontFamily: theme.bodyFont,
          backgroundImage: "url(" + arrow + ")",
          backgroundPosition: "center right 15px",
          backgroundSize: "10px 6px",
          backgroundRepeat: "no-repeat",
          appearance: "none",
          outline: "none",
          "-webkit-appearance": "none",
          "-moz-appearance": "none",
          "-ms-appearance": "none",
          [theme.breakpoints.up("md")]: {
            minWidth: 0,
          },
        },
        "&.show-count": {
          paddingBottom: 12,
        },
      },
      "& .show-style": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
        "& button": {
          width: 60,
          height: 60,
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          fontSize: 0,
          padding: 0,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.3,
          transition:
            "opacity .3s ease-in-out, background-color .3s ease-in-out",
          "&.grid-trigger": {
            //backgroundImage: 'url(' + gridIcon + ')',
          },
          "&.list-trigger": {
            //backgroundImage: 'url(' + listIcon + ')',
          },
          "&:hover, &:focus": {
            opacity: 1,
          },
        },
      },
    },
  },
})

export default styles
