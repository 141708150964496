export default theme => ({ // eslint-disable-line
    catTitle: {
      '& .MuiTypography-root': {
        marginBottom: 12,
      },
      '& p': {
        marginBottom: 0,
        lineHeight: 1.8,
        fontSize: 14,
        [theme.breakpoints.up('md')]: {
          lineHeight: 2,
          fontSize: 16,
        }
      }
    }
 });