export default theme => ({ // eslint-disable-line
  subCatGrid: {
    padding: '32px 0',
    [theme.breakpoints.up('md')]: {
      padding: '52px 0',
    },
    '& .link-item': {
      borderRadius: 8,
      boxShadow: theme.shadow,
      overflow: 'hidden',
      position: 'relative',
      display: 'block',
      '& .name': {
        position: 'absolute',
        background: 'linear-gradient(0deg, rgba(0,49,59,1) 0%, rgba(0,49,59,0.63) 25%, rgba(0,49,59,0) 100%)',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 32,
        fontSize: 24,
        letterSpacing: '0.008em',
        color: theme.palette.secondary.cream,
        fontWeight: 'bold',
        fontFamily: theme.headerFont,
      },
      '& svg': {
        width: 36,
        height: 36,
        position: 'absolute',
        bottom: 32,
        right: 32,
        '& .circ': { fill: theme.palette.primary.green },
        '& .chev': { fill: theme.palette.primary.navy },
      }
    }
  }
});