import React from "react"
import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export default function Paragraph({
  children,
  className,
  align = "left",
  textColor = "initial",
  backgroundColor = "initial",
  dropCap,
}) {
  const classes = useStyles()

  return (
    <p
      className={`${classes.para} ${className}`}
      data-align={align}
      data-override-color={textColor}
      data-override-background={backgroundColor}
      data-drop-cap={dropCap}
    >
      {children}
    </p>
  )
}
