import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"

import styles from "./styles"
import { CoreHeadingBlock } from "../CoreHeadingBlock"
import { CoreParagraphBlock } from "../CoreParagraphBlock"
import { AcfButtonBlock } from "../AcfButtonBlock"
import { GatsbyImage } from "gatsby-plugin-image"

import Link from "../../Link"

const useStyles = makeStyles(theme => styles(theme))

export const AcfAdvertBlock = props => {
  const advertData = props.block.blockAdvert.advert.Advert

  const classes = useStyles()

  return (
    <div className="wrapper">
      <Link
        className={` ${
          props.attributes.className ? props.attributes.className : ""
        } ${classes.advertBlock} `}
        to={advertData.ctaUrl}
      >
        <div className="advert-heading-body-and-button">
          <CoreHeadingBlock
            attributes={{
              textAlign: "left",
              anchor: "",
              className: "",
              content: advertData.title,
              level: 3,
              textColor: "global-white",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
          <CoreParagraphBlock
            attributes={{
              align: "left",
              className: "",
              content: advertData.body,
              textColor: "global-white",
              backgroundColor: "",
              fontSize: 18,
              __typename: "WpCoreParagraphBlockAttributes",
            }}
            innerBlocks={[]}
          />

          <AcfButtonBlock
            attributes={{
              className: "",
            }}
            block={{
              ButtonFields: {
                buttonLink: {
                  target: "_blank",
                  title: advertData.ctaText,
                  url: advertData.ctaUrl,
                },
                buttonStyle: "transparent-green",
                showIcon: "yes",
              },
            }}
            innerBlocks={[]}
          />
        </div>

        <div className="image-wrapper">
          <GatsbyImage
            image={advertData.image.localFile.childImageSharp.gatsbyImageData}
            alt=""
            loading="lazy"
          />
        </div>
      </Link>
    </div>
  )
}

export const AcfAdvertBlockFragment = graphql`
  fragment AcfAdvertBlock on WpBlock {
    ... on WpAcfAdvertBlock {
      blockAdvert {
        advert {
          ... on WpAdvert {
            Advert {
              title
              body
              ctaText
              ctaUrl
              image {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      attributes {
        className
        name
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfAdvertBlockPreview = `
    ... on AcfAdvertBlock {
        blockAdvert {
          advert {
            ... on WpAdvert {
              Advert {
                title
                body
                ctaText
                ctaUrl
                image {                    
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        attributes {
          className
          name
        }
      
    }   
    
`
