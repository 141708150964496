import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/styles"
import Grid from "@material-ui/core/Grid"

import SideNav from "../Components/SideNav"
import SubcategoryNav from "../Components/SubCategoryNav"
import ProductsGrid from "../Components/ProductsGrid"
import ProductsList from "../Components/ProductsList"
import styles from "./CategoryPage.styles"
import CategoryTitle from "../Components/CategoryTitle"
import Breadcrumb from "../../Breadcrumb"
import { setPage, itemsToShow } from "../../../app/actions"
import { useStaticQuery, graphql } from "gatsby"
import CategoryBanner from "../Components/CategoryBanner"
import LatestProducts from "../Components/LatestProducts"

import { AcfAdvertBlock } from "../../blocks/AcfAdvertBlock"
import Key from "../Components/Key"

const mapStateToProps = state => ({
  layout: state.ShopSettings.layout,
  filters: state.ShopSettings.filter,
  itemsToShow: state.ShopSettings.itemsToShow,
  page: state.ShopSettings.page,
  sort: state.ShopSettings.sort,
})

const mapDispatchToProps = dispatch => ({
  setPage: page => dispatch(setPage(page)),
  setItemsToShow: number => dispatch(itemsToShow(number)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class Category extends Component {
  constructor(props) {
    super(props)

    this.props.setPage(1)
  }

  sortProducts(products) {
    const { sort } = this.props

    if (sort === "best") {
      return products
        .sort((a, b) => {
          return b.title.localeCompare(a.title)
        })
        .sort((a, b) => {
          return a.ProductMeta.bestseller ? -1 : 1
        })
    }

    if (sort === "price-lth") {
      return products.sort((a, b) => {
        return a.ProductData.price - b.ProductData.price
      })
    }

    if (sort === "price-htl") {
      return products.sort((a, b) => {
        return b.ProductData.price - a.ProductData.price
      })
    }

    if (sort === "newest") {
      return products.sort((a, b) => {
        return (
          new Date(a.modifiedGmt).getTime() - new Date(b.modifiedGmt).getTime()
        )
      })
    }
    return products
  }

  render() {
    const {
      uri,
      slug,
      products,
      name,
      description,
      wpParent,
      layout,
      id,
      subcategories,
      total,
      data,
      CollectionData,
    } = this.props

    const sortedProducts = this.sortProducts(products)

    const pagetoShow = sortedProducts

    const filteredSubcategories =
      subcategories &&
      subcategories.map(category => {
        return {
          ...category,
          products: category.products,
        }
      })

    const filteredSiblings =
      wpParent &&
      wpParent.node.wpChildren.nodes.map(category => {
        return {
          ...category,
          products: category.products.nodes,
          wpChildren: category.wpChildren.nodes.map(category => {
            return { ...category, products: category.products.nodes }
          }),
        }
      })

    return (
      <>
        <Breadcrumb
          type="category"
          ancestors={wpParent && wpParent.node}
          current={{
            title: name,
            slug: slug,
            uri: uri,
          }}
        />
        <CategoryTitle title={name} description={description} />
        <div className="wrapper" style={{ padding: "0 12px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <SideNav
                name={name}
                uri={uri}
                id={id}
                description={description}
                parent={wpParent}
                subcategories={filteredSubcategories}
                siblings={filteredSiblings}
                products={sortedProducts}
                total={total}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <SubcategoryNav products={sortedProducts} total={total} />
              {layout === "grid" && <ProductsGrid products={pagetoShow} />}
              {layout === "list" && <ProductsList products={pagetoShow} />}
              <Key showFor="mobile" />
            </Grid>
          </Grid>
        </div>
        {data.wp.themeOptions.ThemeOptions.shopSubCategoryAdvertBlock !==
          null && (
          <>
            <AcfAdvertBlock
              attributes={{
                className: "",
                name: "acf/advert",
              }}
              block={{
                attributes: {
                  className: "",
                  name: "acf/advert",
                },
                blockAdvert: {
                  advert:
                    data.wp.themeOptions.ThemeOptions
                      .shopSubCategoryAdvertBlock,
                },
                innerBlocks: [],
              }}
              innerBlocks={[]}
            />
            <br />
            <br />
          </>
        )}
        <CategoryBanner data={CollectionData} />
        <LatestProducts header="Latest Products" />
      </>
    )
  }
}

const Page = withStyles(styles)(connector(Category))

const Wrapper = props => {
  const data = useStaticQuery(graphql`
    query showSubCatPage {
      wp {
        themeOptions {
          ThemeOptions {
            shopSubCategoryAdvertBlock {
              ... on WpAdvert {
                Advert {
                  title
                  body
                  ctaText
                  ctaUrl
                  image {
                    sourceUrl
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return <Page {...props} data={data} />
}

export default Wrapper
