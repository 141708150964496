export default theme => ({ // eslint-disable-line
    breadcrumb: {
        position: 'relative',
        padding: '13px 0',
        zIndex: 2,
        '& nav': {
            flex: '0 0 calc(100% - 24px)',
            margin: '0 auto',
            display: 'none',
            '&.mobile-breadcrumb': {
                display: 'block',
            },
            [theme.breakpoints.up(640)]: {
                '&.mobile-breadcrumb': {
                    display: 'none',
                },
                '&.tablet-breadcrumb': {
                    display: 'block',
                },
            },
            [theme.breakpoints.up(920)]: {
                '&.tablet-breadcrumb': {
                    display: 'none',
                },
                '&.desktop-breadcrumb': {
                    display: 'block',
                },

            },
            '& ol': {
                alignItems: 'flex-start'
            },
            '& li': {
                fontSize: 0,
                whiteSpace: 'none',
                '& svg': {
                    verticalAlign: 'top',
                },
                '& a, & p': {
                    fontSize: 12,
                    lineHeight: 1.6,
                    letterSpacing: '0.016em',
                    textDecoration: 'none',
                    color: theme.palette.primary.purple,
                },
                '& p': {
                    color: 'rgba(0,0,0,0.35)',
                },
                '& a': {
                    '&::after': {
                        content: '">"',
                        marginLeft: 10,
                        transform: 'scale(1, 1.5)',
                        color: 'rgba(0,0,0,0.35)',
                        display: 'inline-block',
                        transformOrigin: 'center',
                    }
                },
                '&.MuiButtonBase-root': {
                    backgroundColor: 'transparent',
                    '&::before': {
                        content: '"<<"',
                        fontSize: 10,
                        lineHeight: 1.6,
                        textTransform: 'uppercase',
                        letterSpacing: '0.152em',
                        textDecoration: 'none',
                        color: theme.palette.primary.purple,
                        fontWeight: 500,
                    },
                    '& svg': {
                        display: 'none',
                    }
                }
            }
        }
    }
});