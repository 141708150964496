export default theme => ({ // eslint-disable-line
    catBanner: {
      position: 'relative',
      marginBottom: 32,
      [theme.breakpoints.up('md')]: {
        marginBottom: 80,
      },
      '& .gatsby-image-wrapper': {
          height: 360,
          width: '100%',
          zIndex: 0,
          '&::after': {
              position: 'absolute',
              left: 0,
              bottom: 0,
              right: 0,
              top: 0,
              content: '""',
              zIndex: 1,
              background: 'linear-gradient(90deg, rgba(247,236,224,1) 30%, rgba(247,236,224,0.8) 70%, rgba(247,236,224,0.7) 100%)'
          }
      },
      '& .wrapper': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
          width: '100%',
          alignItems: 'center',
          '& .con-wrap': {
            maxWidth: 624,
            '& .MuiTypography-root': {
                marginBottom: 32,
                marginRight: 'auto',
            },
            '& p': {
              marginRight: 'auto',
              marginLeft: 0,
              marginBottom: 0,
            }
          }
        }
    },
 });